import React from 'react';
import { ResourceType } from '../../types/resource-type';
import CtaLink from '../atoms/CtaLink';
import TileGrid from '../molecules/TileGrid';

interface FeaturedResourcesProps {
  lead?: string;
  title: string;
  body?: string;
  cta?: {
    label: string;
    to: string;
  };
  resources: {
    title: string;
    nodeType: ResourceType;
    dateGmt: string;
    slug: string;
    featuredimage?: {
      node: {
        sourceUrl: string;
      };
    };
    event?: {
      eventDate: string;
    };
  }[];
  noBorder?: boolean;
}

const FeaturedResources: React.FC<FeaturedResourcesProps> = ({
  lead,
  title,
  body,
  cta,
  resources,
  noBorder,
}) => (
  <section className="py-16 md:py-32">
    <div className="items-end justify-between mb-12 md:mb-16 md:flex u-container">
      <div className="flex-grow-0 flex-shrink-0 w-full md:w-8/12 lg:w-6/12">
        {lead && <div className="u-s1">{lead}</div>}
        <h2 className="u-h2">
          {title}
        </h2>
        {body && <p className="mb-5 u-p1 md:mb-6">{body}</p>}

        {cta && <CtaLink {...cta} />}
      </div>
    </div>
    <div className="u-container">
      <TileGrid resources={resources.slice(0, 3)} noBorder={noBorder} />
    </div>
  </section>
);

export default FeaturedResources;
