import React from 'react';
import { ResourceType } from '../../types/resource-type';
import ResourceTile from '../atoms/ResourceTile';

interface TileGridProps {
  cols?: number;
  resources: {
    title: string;
    nodeType: ResourceType;
    dateGmt: string;
    slug: string;
    featuredimage?: {
      node: {
        sourceUrl: string;
      };
    };
    event?: {
      eventDate: string;
    };
  }[];
  noBorder?: boolean;
}

const TileGrid: React.FC<TileGridProps> = ({ cols, resources, noBorder }) => (
  <div
    className={`justify-between space-y-6 lg:space-y-0 lg:space-x-6 lg:flex ${
      cols && 'flex-wrap'
    }`}
  >
    {resources.map((resource, index) => (
      <div key={index} className="flex-grow-0 w-full">
        <ResourceTile {...resource} noBorder={noBorder} />
      </div>
    ))}
  </div>
);

export default TileGrid;
