import React from 'react';

interface CreativeFreedomSectionProps {
  lead?: string;
  title: string;
  body: string;
  image: {
    sourceUrl: string;
    altText: string;
  };
  backgroundImage?: string;
}

const CreativeFreedomSection: React.FC<CreativeFreedomSectionProps> = ({
  lead,
  title,
  body,
  image,
  backgroundImage,
}) => (
  <section
    className="py-16 bg-cover md:py-32"
    style={{
      backgroundImage: `url(${
        backgroundImage || '/images/modular-approach-bg.jpg'
      })`,
    }}
  >
    <div className="text-center u-container">
      {lead && (
        <div className="mb-5 text-lg font-semibold text-tertiary opacity-40">
          {lead}
        </div>
      )}
      <h2 className="text-white u-h2">{title}</h2>
      <p className="max-w-full mx-auto text-lg text-white w-160 mb-18 lg:mb-20">
        {body}
      </p>
      <img
        src={image?.sourceUrl}
        alt={image?.altText}
        className="w-full h-auto"
      />
    </div>
  </section>
);

export default CreativeFreedomSection;
