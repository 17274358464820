import React from 'react';
import { IAuthor } from '../../interfaces/author';
import { IImage } from '../../interfaces/image';

interface ProductPageTestimonialProps {
  backgroundImage: string;
  logo: IImage;
  quote: string;
  author: IAuthor;
}

const ProductPageTestimonial: React.FC<ProductPageTestimonialProps> = ({
  backgroundImage,
  logo,
  quote,
  author,
}) => (
  <section
    className="py-16 bg-cover md:py-32"
    style={{ backgroundImage: `url(${backgroundImage})` }}
  >
    <div className="flex flex-col items-center text-center text-white u-container">
      <img
        src={logo?.sourceUrl}
        alt={logo?.altText}
        className="w-auto h-10 mb-8 md:mb-13"
      />
      <q className="w-full max-w-screen-md mx-auto mb-10 text-2xl font-semibold leading-snug md:mb-16 lg:text-4xl quotes-none">
        {quote}
      </q>
      <img
        src={author.photo.sourceUrl}
        alt={author.photo.altText}
        className="mb-5 border-white rounded-full w-15 h-15 border-3 border-opacity-40"
      />
      <div className="font-semibold">{author.name}</div>
      <div>{author.title}</div>
    </div>
  </section>
);

export default ProductPageTestimonial;
