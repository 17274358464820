import { graphql } from 'gatsby';
import moment from 'moment';
import React from 'react';
import Layout from '../components/base/Layout';
import Seo from '../components/base/Seo';
import BenefitSection from '../components/organisms/BenefitSection';
import CaseStudySection from '../components/organisms/CaseStudySection';
import CreativeFreedomSection from '../components/organisms/CreativeFreedomSection';
import FeaturedResources from '../components/organisms/FeaturedResources';
import ProductPageHero from '../components/organisms/ProductPageHero';
import ProductPageTestimonial from '../components/organisms/ProductPageTestimonial';

const BuildCreativeAtScalePage = ({
  data: { page: data, allWpPost, allWpGuide, allWpEvent, allWpNewsItem },
}) => {
  const latestPosts = [
    ...allWpPost.edges.map(({ node }) => node),
    ...allWpGuide.edges.map(({ node }) => node),
    ...allWpEvent.edges.map(({ node }) => node),
    ...allWpNewsItem.edges.map(({ node }) => node),
  ].sort((a, b) => (moment(a.dateGmt) < moment(b.dateGmt) ? 1 : -1));

  return (
    <Layout>
      <Seo post={data} />

      <ProductPageHero {...data.buildCreativeAtScale.hero} />
      {data.buildCreativeAtScale.benefits.length > 0 && (
        <BenefitSection
          imageSide="right"
          animationName="spirable-create-scale"
          animationBackground="linear-gradient(336.42deg, #2DD3C9 -10%, rgba(10, 101, 179, 0.6) 115%, rgba(10, 98, 179, 0.76) 119%)"
          {...data.buildCreativeAtScale.benefits[0]}
        />
      )}
      <ProductPageTestimonial
        backgroundImage="/images/build-creative-at-scale-testimonial-bg.jpg"
        {...data.buildCreativeAtScale.testimonial}
      />
      {data.buildCreativeAtScale.benefits.length > 1 && (
        <BenefitSection
          imageSide="left"
          {...data.buildCreativeAtScale.benefits[1]}
        />
      )}
      <CreativeFreedomSection
        {...data.buildCreativeAtScale.creativeFreedomSection}
      />
      {data.buildCreativeAtScale.benefits.length > 2 && (
        <BenefitSection
          imageSide="right"
          {...data.buildCreativeAtScale.benefits[2]}
        />
      )}
      {data.buildCreativeAtScale.benefits.length > 3 && (
        <BenefitSection
          imageSide="left"
          {...data.buildCreativeAtScale.benefits[3]}
        />
      )}
      <CaseStudySection
        {...data.buildCreativeAtScale.caseStudy}
        backgroundImage="linear-gradient(140.65deg, rgba(10, 98, 179, 0.73) -35.98%, #2CD2C8 86.26%)"
      />
      <FeaturedResources
        {...data.buildCreativeAtScale.featuredResources}
        resources={latestPosts}
      />
    </Layout>
  );
};

export const pageQuery = graphql`
  query BuildCreativeAtScaleQuery($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      slug
      ...SEO
      buildCreativeAtScale {
        hero {
          title
          body
          image {
            ...Image
          }
        }
        benefits {
          lead
          title
          body
          image {
            ...Image
          }
          logo {
            title
            image {
              ...Image
            }
          }
          note
          cta {
            label
            to
          }
        }
        testimonial {
          logo {
            ...Image
          }
          quote
          author {
            name
            title
            photo {
              ...Image
            }
          }
        }
        caseStudy {
          logo {
            ...Image
          }
          title
          body
          cta {
            label
            to
          }
          quote
          author {
            name
            title
            photo {
              ...Image
            }
          }
        }
        creativeFreedomSection {
          lead
          title
          body
          image {
            ...Image
          }
        }
        featuredResources {
          lead
          title
          body
          cta {
            label
            to
          }
        }
      }
    }
    allWpPost(sort: { fields: dateGmt, order: DESC }, limit: 3) {
      edges {
        node {
          date
          title
          nodeType
          slug
          featuredImage {
            node {
              ...Image
            }
          }
        }
      }
    }
    allWpEvent(sort: { fields: dateGmt, order: DESC }, limit: 3) {
      edges {
        node {
          title
          nodeType
          slug
          dateGmt
          featuredImage {
            node {
              ...Image
            }
          }
          event {
            eventDate
          }
        }
      }
    }
    allWpGuide(sort: { fields: dateGmt, order: DESC }, limit: 3) {
      edges {
        node {
          title
          nodeType
          slug
          dateGmt
          featuredImage {
            node {
              ...Image
            }
          }
        }
      }
    }
    allWpNewsItem(sort: { fields: dateGmt, order: DESC }, limit: 3) {
      edges {
        node {
          title
          nodeType
          slug
          dateGmt
          featuredImage {
            node {
              ...Image
            }
          }
        }
      }
    }
  }
`;

export default BuildCreativeAtScalePage;
